import { AuthorizedContent } from '@frontegg/react';
import { startOfMonth } from 'date-fns';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR, { useSWRConfig } from 'swr';
import createDashboardUpdateFetcher from '../../api/dashboard/updateDashoard';
import deleteFetcher from '../../api/deleteFetcher';
import RolesType from '../../utils/enum/RolesType';
import useFetcher from '../../utils/hooks/useFetcher';
import { CHART_INDICATOR_NAME } from '../Modals/DashboardModal/constants';
import { FilterResults } from '../Modals/FilterModal/filterModalInterface';
import { SurveyTemporalFilterType } from '../Surveys/surveyInterface';
import AddIndicatorButton from './AddIndicatorButton/addIndicatorButton';
import DashboardHeader from './DashboardHeader/dashboardHeader';
import {
    Indicator,
    IndicatorData,
    DonutProps,
    VerbatimProps,
    DashboardFromApi,
    UpdateDashboardProps,
    FilterFromApi,
    ClauseFilterProps,
    ClauseFilterLabelProps,
    DashboardParams,
    DonutFromApiProps,
    KeyFigureFromApiProps,
    ScopeProps,
    ScopeLabelProps,
    TemporalScopeFromApi,
} from './dashboardInterfaces';
import FilterBar from './FilterBar/filterBar';
import Gradient from './GradientColor/Gradient';
import DonutIndicator from './IndicatorCard/donutIndicator';
import GlobalVerbatimIndicator from './IndicatorCard/globalVerbatimIndicator';
import HorizontalBarIndicator from './IndicatorCard/horizontalBarIndicator';
import IndicatorCard from './IndicatorCard/indicatorCard';
import KeyFigureIndicator from './IndicatorCard/keyFigureIndicator';
import LineChartIndicator from './IndicatorCard/lineChartIndicator';
import VerbatimIndicator from './IndicatorCard/verbatimIndicator';
import { ContainerDashboardCharts, ColumnDashboardCharts, DashboardStyled, SelectFiltersBar } from './styled';
import TemporalScope from './TemporalScope/temporalScope';

function isKeyFigureProps(object: IndicatorData): object is KeyFigureFromApiProps {
    return 'data' in object;
}

function isDonutProps(object: IndicatorData): object is DonutFromApiProps {
    return 'donut_result' in object;
}

function isVerbatimProps(object: IndicatorData): object is VerbatimProps {
    return 'positive' in object;
}

const Dashboard: FC = () => {
    const { t } = useTranslation('dashboard');
    const { mutate } = useSWRConfig();
    const { surveyId = 'unknown', dashboardId } = useParams<DashboardParams>();
    const [updatedDasboard, setUpdatedDasboard] = useState<UpdateDashboardProps>();
    const dashboardUrl = `${process.env.REACT_APP_API_URL}/survey/${surveyId}/dashboard/${dashboardId}`;
    const fetcher = useFetcher();
    const { data: fetchedDashboard, error } = useSWR(dashboardUrl, fetcher);
    const { data: fetchedIndicators } = useSWR(`${dashboardUrl}/indicator`, fetcher);
    const { data: fetchedSurvey } = useSWR(`${process.env.REACT_APP_API_URL}/survey/${surveyId}`, fetcher);
    const navigate = useNavigate();

    const [filters, setFilters] = useState<FilterFromApi[]>([]);
    const [temporalScopes, setTemporalScope] = useState<TemporalScopeFromApi[]>([]);
    const [selectedClauseFilter, setSelectedClauseFilter] = useState<Array<ClauseFilterProps>>([]);
    const [selectedClauseFilterLabel, setSelectedClauseFilterLabel] = useState<Array<ClauseFilterLabelProps>>([]);
    const [selectedScope, setSelectedScope] = useState<Array<ScopeProps>>([]);
    const [selectedScopeLabel, setSelectedScopeLabel] = useState<Array<ScopeLabelProps>>([]);
    const [resetFilters, setResetFilters] = useState<number>(0);

    const [dateRange, setDateRange] = useState<any>({
        selection: {
            startDate: startOfMonth(new Date()),
            endDate: new Date(),
            key: 'selection',
        },
    });
    const constructDashboardFilterConfigFromApiToUpdate = (dashboardFilterConfig: DashboardFromApi['dashboardFilterConfig']): UpdateDashboardProps['dashboardFilterConfig'] => (dashboardFilterConfig.map((dashboardFilter: FilterFromApi) => ({ filterId: Object.keys(dashboardFilter.filter)[0], modalitiesIds: Object.keys(dashboardFilter.modalities) })));
    const handleSelectClauseFilter = ([filterId, modalityId]: [string, Array<string> | null]): void => {
        const indexOf = selectedClauseFilter.findIndex((alreadySelected: ClauseFilterProps) => alreadySelected.filterId === filterId);

        if (modalityId !== null && modalityId?.length !== 0) {
            if (indexOf === -1) {
                setSelectedClauseFilter([...selectedClauseFilter, { filterId, modalityId }]);
            } else {
                const newSelectedClauseFilter = [...selectedClauseFilter];
                newSelectedClauseFilter[indexOf] = { filterId, modalityId };
                setSelectedClauseFilter(newSelectedClauseFilter);
            }
        } else {
            const newSelectedClauseFilter = [...selectedClauseFilter];
            newSelectedClauseFilter.splice(indexOf, 1);
            if (indexOf !== -1) {
                setSelectedClauseFilter(newSelectedClauseFilter);
            }
        }
    };

    const handleSelectClauseFilterLabel = ([filterLabel, modalitiesLabel]: [string, string | null]): void => {
        const indexOf = selectedClauseFilterLabel.findIndex((alreadySelected: ClauseFilterLabelProps) => alreadySelected.filterLabel === filterLabel);

        if (modalitiesLabel !== null && modalitiesLabel?.length !== 0) {
            if (indexOf === -1) {
                setSelectedClauseFilterLabel([...selectedClauseFilterLabel, { filterLabel, modalitiesLabel }]);
            } else {
                const newSelectedClauseFilterLabel = [...selectedClauseFilterLabel];
                newSelectedClauseFilterLabel[indexOf] = { filterLabel, modalitiesLabel };
                setSelectedClauseFilterLabel(newSelectedClauseFilterLabel);
            }
        } else {
            const newSelectedClauseFilterLabel = [...selectedClauseFilterLabel];
            newSelectedClauseFilterLabel.splice(indexOf, 1);
            if (indexOf !== -1) {
                setSelectedClauseFilterLabel(newSelectedClauseFilterLabel);
            }
        }
    };

    const handleSelectedScope = ([scopeId, modalityId]: [string, Array<string> | null]): void => {
        const indexOf = selectedScope.findIndex((alreadySelected: ScopeProps) => alreadySelected.scopeId === scopeId);

        if (modalityId !== null && modalityId?.length !== 0) {
            if (indexOf === -1) {
                setSelectedScope([...selectedScope, { scopeId, modalityId }]);
            } else {
                const newSelectedScope = [...selectedScope];
                newSelectedScope[indexOf] = { scopeId, modalityId };
                setSelectedScope(newSelectedScope);
            }
        } else {
            const newSelectedScope = [...selectedScope];
            newSelectedScope.splice(indexOf, 1);
            if (indexOf !== -1) {
                setSelectedScope(newSelectedScope);
            }
        }
    };

    const handleChangeDateRangeScope = (newDateRange: any): void => {
        setDateRange(newDateRange);
    };

    const handleSelectedScopeLabel = ([scopeLabel, modalitiesLabel]: [string, string | null]): void => {
        const indexOf = selectedScopeLabel.findIndex((alreadySelectedLabel: ScopeLabelProps) => alreadySelectedLabel.scopeLabel === scopeLabel);

        if (modalitiesLabel !== null) {
            if (indexOf === -1) {
                setSelectedScopeLabel([...selectedScopeLabel, { scopeLabel, modalitiesLabel }]);
            } else {
                const newSelectedScopeLabel = [...selectedScopeLabel];
                newSelectedScopeLabel[indexOf] = { scopeLabel, modalitiesLabel };
                setSelectedScopeLabel(newSelectedScopeLabel);
            }
        } else {
            const newSelectedScopeLabel = [...selectedScopeLabel];
            newSelectedScopeLabel.splice(indexOf, 1);
            if (indexOf !== -1) {
                setSelectedScopeLabel(newSelectedScopeLabel);
            }
        }
    };

    const handleResetClauseFilter = (hasResetFilters: number) => {
        setResetFilters(resetFilters + hasResetFilters);
    };

    useEffect(() => {
        if (fetchedDashboard?.dashboardFilterConfig.length > 0) {
            const selectedClauseLockedFilters = fetchedDashboard?.dashboardFilterConfig.filter((filter: FilterFromApi) => filter.lockedModalities)?.map((filter: any) => (
                { filterId: Object.keys(filter.filter)[0], modalityId: filter.lockedModalities.toString().split(',') }
            ));
            const selectedClauseLockedFiltersLabel = fetchedDashboard?.dashboardFilterConfig.filter((filter: FilterFromApi) => filter.lockedModalities)?.map((filter: any) => (
                { filterLabel: Object.values(filter.filter)[0], modalitiesLabel: Object.entries(filter.modalities).filter((modality) => String(filter.lockedModalities).includes(modality[0])).map((modality) => modality[1]).join(', ') }
            ));
            setSelectedClauseFilter(selectedClauseLockedFilters ?? []);
            setSelectedClauseFilterLabel(selectedClauseLockedFiltersLabel ?? []);
        } else {
            setSelectedClauseFilter([]);
            setSelectedClauseFilterLabel([]);
        }
    }, [fetchedDashboard, resetFilters]);

    useEffect(() => {
        if (error) {
            navigate('/');
        }
    }, [error]);

    const constructUpdateDashboard = (dashboard: DashboardFromApi): UpdateDashboardProps => (
        {
            id: dashboard.id,
            name: dashboard.name,
            surveyId: dashboard.surveyId,
            rankOfIndicator: dashboard.rankOfIndicator,
            slug: dashboard.slug,
            customSurveyTitle: dashboard.customSurveyTitle,
            dashboardFilterConfig: constructDashboardFilterConfigFromApiToUpdate(dashboard.dashboardFilterConfig),
        }
    );

    useEffect(() => {
        if (fetchedDashboard) {
            setUpdatedDasboard(constructUpdateDashboard(fetchedDashboard));
            setFilters(fetchedDashboard.dashboardFilterConfig);
            setTemporalScope(fetchedDashboard.temporalScopesConfig ? fetchedDashboard.temporalScopesConfig : []);
        }
    }, [fetchedDashboard]);

    const addDashboardFilter = (newFilters: FilterResults): void => {
        const newUpdatedDasboard: UpdateDashboardProps = { ...updatedDasboard, dashboardFilterConfig: newFilters };
        createDashboardUpdateFetcher(
            surveyId,
            newUpdatedDasboard,
            t('addFilter.modal.success'),
            t('addFilter.modal.error'),
        );
        mutate(dashboardUrl);
    };

    const removeDashboardFilter = (filterId: string): void => {
        const urlToDeleteFilter = `${process.env.REACT_APP_API_URL}/survey/${surveyId}/dashboard/${dashboardId}/filterConfig/${filterId}`;
        deleteFetcher(t('removeFilter.success'), t('removeFilter.error'), urlToDeleteFilter);
        mutate(dashboardUrl);
        handleSelectClauseFilter([filterId, []]);
    };

    const selectIndicatorCard = (additionalVars: Indicator['additionalVars'], displayType: Indicator['displayType'], data: Indicator['data'], question: Indicator['question']) => {
        if (data === undefined) {
            return null;
        }
        if (displayType === CHART_INDICATOR_NAME.KEY_FIGURE && isKeyFigureProps(data)) {
            return (
                <KeyFigureIndicator
                    computeMethod={Object.keys(data.data)[0]}
                    keyFigureNumber={Object.values(data.data)}
                    previousNumber={data?.previousNumber}
                    round={data?.round}
                    year={data?.year}
                />
            );
        }

        if (displayType === CHART_INDICATOR_NAME.DONUT && isDonutProps(data)) {
            let backgroundDonut = ['#22C55E', '#ff0000'];

            if (Object.values(data.donut_result).length > 2) {
                const firstGradient = new Gradient()
                    .setColorGradient('22C55E', 'FFCC00')
                    .setMidpoint(Object.values(data.donut_result).length / 2)
                    .getColors();
                const secondGradient = new Gradient()
                    .setColorGradient('F97316', 'ff0000')
                    .setMidpoint(Object.values(data.donut_result).length / 2)
                    .getColors();
                backgroundDonut = [...firstGradient, ...secondGradient];
            }
            const donutIndication: DonutProps = {
                keyFigure: data.donut_key_result ?? '',
                hasIndicatorKpiGroup: data.has_indicator_kpi_group,
                labels: Object.keys(data.donut_result),
                datasets: [{ label: 'label', data: Object.values(data.donut_result), backgroundColor: backgroundDonut }],
                round: data.round,
            };

            return (
                <DonutIndicator
                    donutIndication={donutIndication}
                />
            );
        }
        if (displayType === CHART_INDICATOR_NAME.VERBATIM_PN && isVerbatimProps(data)) {
            return (
                <VerbatimIndicator negative={data.negative} positive={data.positive} />
            );
        }
        if (displayType === CHART_INDICATOR_NAME.VERBATIM) {
            return (
                <GlobalVerbatimIndicator question={question} selectedClauseFilterLabel={selectedClauseFilterLabel} verbatims={data} />
            );
        }
        if (displayType === CHART_INDICATOR_NAME.HORIZONTAL_BAR) {
            return (
                <HorizontalBarIndicator
                    listOfValues={data}
                />
            );
        }
        if (displayType === CHART_INDICATOR_NAME.LINE_CHART) {
            return (
                <LineChartIndicator
                    listOfValues={data}
                />
            );
        }

        return undefined;
    };

    const [indicatorsByColumns, setIndicatorByColumns] = useState<{[key: number]: Array<Indicator>}>({
        0: [],
        1: [],
        2: [],
    });

    useEffect(() => {
        if (fetchedIndicators) {
            let columnCounter = 0;
            const constructIndicatorsByColumns : {[key: number]: Array<Indicator>} = {
                0: [],
                1: [],
                2: [],
            };
            fetchedIndicators.forEach((indicator: Indicator) => {
                constructIndicatorsByColumns[columnCounter].push(indicator);
                columnCounter++;
                if (columnCounter === 3) { columnCounter = 0; }
            });
            setIndicatorByColumns(constructIndicatorsByColumns);
        }
    }, [fetchedIndicators]);

    return (
        <DashboardStyled>
            <DashboardHeader name={fetchedSurvey?.customSurveyTitle || fetchedSurvey?.name} />
            <SelectFiltersBar>
                <TemporalScope
                    dashboardId={Number(dashboardId)}
                    dateRange={dateRange}
                    handleChangeDateRangeScope={handleChangeDateRangeScope}
                    handleSelectedScope={handleSelectedScope}
                    handleSelectedScopeLabel={handleSelectedScopeLabel}
                    maxDate={new Date()}
                    minDate={new Date(fetchedSurvey?.minDate)}
                    selectedScopeLabel={selectedScopeLabel}
                    temporalFilterType={fetchedSurvey?.temporalFilterType}
                    temporalScopes={temporalScopes}
                />
                <FilterBar
                    alreadySelectedFilter={updatedDasboard?.dashboardFilterConfig}
                    filters={filters}
                    handleResetClauseFilter={handleResetClauseFilter}
                    handleSelectClauseFilter={handleSelectClauseFilter}
                    handleSelectClauseFilterLabel={handleSelectClauseFilterLabel}
                    removeDashboardFilter={removeDashboardFilter}
                    resetFilters={resetFilters}
                    selectedClauseFilterLabel={selectedClauseFilterLabel}
                    setUpdatedDasboard={setUpdatedDasboard}
                    updateDashboard={addDashboardFilter}
                />
            </SelectFiltersBar>
            <ContainerDashboardCharts>
                {Object.keys(indicatorsByColumns).map((columnNumber: string) => (
                    <ColumnDashboardCharts key={columnNumber}>
                        <AuthorizedContent requiredRoles={[RolesType.ADMIN, RolesType.ADMIN_MOAI]}>
                            {columnNumber === '0' && (
                                <AddIndicatorButton label={t('addIndicator')} />
                            )}
                        </AuthorizedContent>
                        {indicatorsByColumns[parseInt(columnNumber, 10)].map((indicator: Indicator) => (
                            <IndicatorCard
                                key={indicator.id}
                                indicator={indicator}
                                selectIndicatorCard={selectIndicatorCard}
                                selectedClauseFilter={selectedClauseFilter}
                                selectedDateRange={fetchedSurvey.temporalFilterType === SurveyTemporalFilterType.DATE ? dateRange.selection : undefined}
                                selectedScope={selectedScope}
                            />
                        ))}
                    </ColumnDashboardCharts>
                ))}
            </ContainerDashboardCharts>
        </DashboardStyled>
    );
};

export default Dashboard;
