export const colors = {
    lighten: '#F6FFFD',
    default: '#14777B',
    defaultShadow: '0px 10px 32px rgba(20, 119, 123, 0.24)',
    defaultHover: '#09585B',
    grey000: '#FFFFFF',
    grey100: '#0F172A',
    grey200: '#334155',
    grey300: '#64748B',
    grey400: '#94A3B8',
    grey500: '#CBD5E1',
    grey600: '#E2E8F0',
    grey700: '#F1F5F9',
    grey800: '#F8FAFC',
    grey900: '#787878',
    grey1000: '#A1A1A1',
    grey1100: '#BBBBBB',
    alertDanger: '#FF4338',
    alertWarning: '#F97316',
    alertSuccess: '#22C55E',
    alertInfo: '#0060F9',
    black000: '#1E293B',
    blue000: '#3B82F6',
    defaultRGBA: 'rgba(33, 165, 170, 0.24)',
    blue000RGBA: 'rgba(59, 130, 246, 0.24)',
    dateRangeDefault: 'rgba(95, 187, 190, 1)',
    defaultDisabled: '#CEE2E4',
    defaultDarker: '#034446',
    greyDisabled: '#8C8C8C',
};

export default colors;

export const donutBackgroundColor = [
    '#22C55E',
    '#0060F9',
    '#FF4338',
    '#F97316',
    '#64748B',
    '#FAD55C',
    '#4C24CE',
    '#22D3EE',
    '#E879f9',
    '#f472b6',
    '#6366f1',
];
