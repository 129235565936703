import { AuthorizedContent } from '@frontegg/react';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { colors, fontSize, fontWeight, TypographyStyledGlobal } from '../../../theme';
import { EditIconStyled } from '../../../theme/style';
import AlertType from '../../../utils/enum/AlertType';
import RolesType from '../../../utils/enum/RolesType';
import useFetcher from '../../../utils/hooks/useFetcher';
import Loader from '../../Loader/loader';
import Message from '../../Message/Message';
import DeleteIndicatorModal from '../../Modals/ConfirmationModal/DeleteIndicator';
import { getLastPageForEditKpi } from '../../Modals/DashboardModal/constants';
import DashboardModal from '../../Modals/DashboardModal/dashboardModal';
import EditIndicatorModal from '../../Modals/EditModal/EditIndicator';
import ActionsCard from '../ActionsCard/actionsCard';
import { ClauseFilterProps, DashboardParams, DateRangeScopeProps, Indicator, ScopeProps } from '../dashboardInterfaces';
import { IndicatorCardStyled, IndicatorCardHeader, IndicatorCardHeaderBase, ButtonCardStyled, ButtonDivStyled } from '../styled';

interface IndicatorCardProps {
    indicator: Indicator
    selectIndicatorCard: (additionalVars: Indicator['additionalVars'], displayType: Indicator['displayType'], data: Indicator['data'], question: Indicator['question']) => any,
    selectedClauseFilter: Array<ClauseFilterProps>
    selectedScope: Array<ScopeProps>
    selectedDateRange: DateRangeScopeProps | null
}

const IndicatorCard: FC<IndicatorCardProps> = ({ indicator, selectIndicatorCard, selectedClauseFilter = [], selectedScope = [], selectedDateRange }) => {
    const { t } = useTranslation('dashboard');
    const { surveyId, dashboardId } = useParams<DashboardParams>();
    const clauseFilters = JSON.stringify(selectedClauseFilter.map((clauseFilter: ClauseFilterProps) => ({ [clauseFilter.filterId]: clauseFilter.modalityId.map((modalityId: string) => parseInt(modalityId, 10)) })));
    const selectedScopes = JSON.stringify(selectedScope.map((scope: ScopeProps) => ({ [scope.scopeId]: scope.modalityId.map((modalityId: string) => parseInt(modalityId, 10)) })));
    const selectedDateRanges = JSON.stringify({ startDate: selectedDateRange?.startDate.toLocaleDateString('fr-CA'), endDate: selectedDateRange?.endDate.toLocaleDateString('fr-CA'), key: selectedDateRange?.key });
    const filterScopeArray = [selectedClauseFilter.length > 0 ? `filters=${clauseFilters}` : '', selectedScope.length > 0 ? `scopes=${selectedScopes}` : '', selectedDateRange ? `dateRanges=${selectedDateRanges}` : ''].filter((fs: string) => fs !== '');
    const filterScopeLink = filterScopeArray.length > 0 ? `/?${filterScopeArray.join('&')}` : '';
    const { data } = useSWR(`${process.env.REACT_APP_API_URL}/survey/${surveyId}/dashboard/${dashboardId}/compute/${indicator.id}/${indicator.displayType}${filterScopeLink}`, useFetcher());
    const content = selectIndicatorCard(indicator.additionalVars, indicator.displayType, data, indicator.question);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);

    const handleDeleteCard = () => {
        setOpenDeleteModal(true);
    };
    const handleEditTitleCard = () => {
        setOpenEditModal(true);
    };

    const [openModal, setOpenModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const { data: editIndicator } = useSWR(isEdit ? `${process.env.REACT_APP_API_URL}/survey/${surveyId}/dashboard/${dashboardId}/indicator/${indicator.id}` : null, useFetcher());
    const { data: editQuestion } = useSWR(editIndicator ? `${process.env.REACT_APP_API_URL}/survey/${surveyId}/dashboard/${dashboardId}/questions/${editIndicator.questionId}` : null, useFetcher());

    return (
        <IndicatorCardStyled>
            <IndicatorCardHeader>
                <TypographyStyledGlobal size={fontSize.m} weight={fontWeight.semiBold}>
                    {indicator.customItemTitle || indicator.question.label}
                    <AuthorizedContent requiredRoles={[RolesType.ADMIN, RolesType.ADMIN_MOAI]}>
                        <ButtonCardStyled onClick={handleEditTitleCard} variant='text'>({t('edit')})</ButtonCardStyled>
                    </AuthorizedContent>
                </TypographyStyledGlobal>
                <ButtonDivStyled>
                    <EditIconStyled
                        onClick={() => {
                            setOpenModal(true);
                            setIsEdit(true);
                        }}
                        sx={{ width: '0.8em', height: '0.92em' }}
                    />
                    <ActionsCard handleDeleteCard={handleDeleteCard} />
                </ButtonDivStyled>
            </IndicatorCardHeader>
            <IndicatorCardHeaderBase>
                <TypographyStyledGlobal color={colors.grey400} fontStyle='italic' size={fontSize.sm} weight={fontWeight.semiRegular}>
                    { t('base', { count: data?.base }) }
                </TypographyStyledGlobal>
            </IndicatorCardHeaderBase>
            {data?.error && <Message content={data.error} messageType={AlertType.WARNING} />}
            {content ?? <Loader size={50} />}
            <DeleteIndicatorModal indicator={indicator} openModal={openDeleteModal} setOpenModal={setOpenDeleteModal} />
            <EditIndicatorModal indicator={indicator} openModal={openEditModal} setOpenModal={setOpenEditModal} />
            <DashboardModal defaultView={getLastPageForEditKpi(indicator.displayType)} editIndicator={editIndicator} editQuestion={editQuestion} isEdit={isEdit} openModal={openModal} setOpenModal={setOpenModal} />
        </IndicatorCardStyled>
    );
};

export default IndicatorCard;
