import { Box, FormGroup, Modal } from '@mui/material';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colors, fontSize, fontWeight } from '../../theme';
import { ButtonStyled } from '../../theme/style';
import { ActionButtonStyledProps } from './DashboardModal/DashboardModalContent/styled';

const BoxModalStyled = styled(Box)`
  background: ${colors.grey800};
  font-weight: ${fontWeight.semiRegular};
  font-size: ${fontSize.sm};
  color: ${colors.grey400};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 48px 32px 32px;
  border-radius: 8px;
  width: 580px;
  &:focus-visible {
    outline:none;
  }
`;

export const BigBoxModalStyled = styled(Box)`
  background: ${colors.grey800};
  font-weight: ${fontWeight.semiRegular};
  font-size: ${fontSize.sm};
  color: ${colors.grey400};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 48px 32px 32px;
  border-radius: 8px;
  max-width: 1500px;
  width: 80%;
  height: 90%;
  max-height: 900px;
  &:focus-visible {
    outline:none;
  }
`;

export const ActionButtonStyled = styled(ButtonStyled)((props : ActionButtonStyledProps) => `
    && {
        padding: 12px 16px;
        background-color: ${props.default ? colors.default : colors.grey000};
        color: ${props.default ? colors.grey000 : colors.default};
        font-weight: ${fontWeight.semiBold};
        max-height: 48px;
        &:hover {
            background-color: ${props.default ? colors.defaultHover : colors.grey700};
        }
        &:disabled {
            background-color: ${colors.grey600};
            color: ${colors.grey300};
        }
    }
`);

export const ErrorInputMessageStyled = styled.p`
    color: ${colors.alertDanger};
    padding: 8px 0 0;
    margin: 0;
`;
export const ActionButtonContainer = styled.div`
    display: flex;
    gap: 16px;
    margin-top: 32px;
`;
export const ModalStyled = styled(Modal)`
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
`;

export const LinkModal = styled(Link)`
    && {
        font-weight: ${fontWeight.semiBold};
        font-size: ${fontSize.m};
        color: ${colors.default};
        margin-top: 28px;
        margin-bottom: 32px;
        display: flex;
        justify-content: flex-end;
        text-decoration: underline;
    }
`;

export const FormGroupStyled = styled(FormGroup)`
    && {
        max-height: 40vh;
        overflow-y: scroll;
        display: flex;
        flex-wrap: nowrap;

        &&::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 7px;
        }
        &&::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background-color: ${colors.grey600};
          }
    }
`;

export const OneLineTitleStyled = styled.div`
    display: flex;
    padding-top: 10px;
`;

export default BoxModalStyled;
