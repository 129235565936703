import React, { FC } from 'react';
import { fontWeight, TypographyStyledGlobal } from '../../../../../theme';
import { useChartElement, QUESTION_TYPE, CHART_INDICATOR_NAME } from '../../constants';
import { SelectIndicatorContentProps, CreateIndicatorFetchProps } from '../../dashboardModalInterface';
import { ActionBackButtonStyled, CardStyled, FlexBox } from '../styled';

const SelectIndicatorDisplayType: FC<SelectIndicatorContentProps> = ({
    setCurrentContent,
    setCreateIndicator,
    selectedQuestion,
}) => {
    const handleClick = (typeIndicator: CHART_INDICATOR_NAME, nextStep: string) => {
        setCreateIndicator((previousCreationIndicator: CreateIndicatorFetchProps) => (
            { ...previousCreationIndicator, displayType: typeIndicator }
        ));
        setCurrentContent(nextStep);
    };

    const isDisabledChart: { [key: string]: (questionType: string, isMultiple : boolean) => boolean} = {

        [QUESTION_TYPE.NUMERIC]: (questionType) => questionType === CHART_INDICATOR_NAME.VERBATIM || questionType === CHART_INDICATOR_NAME.VERBATIM_PN || questionType === CHART_INDICATOR_NAME.HORIZONTAL_BAR || questionType === CHART_INDICATOR_NAME.DONUT,

        [QUESTION_TYPE.CLOSED_DATA]: (questionType: string, isMultiple : boolean) => (
            (questionType === CHART_INDICATOR_NAME.VERBATIM || questionType === CHART_INDICATOR_NAME.VERBATIM_PN) && !isMultiple)
        || (questionType === CHART_INDICATOR_NAME.HORIZONTAL_BAR && isMultiple
        ),

        [QUESTION_TYPE.OPEN]: (questionType) => questionType !== CHART_INDICATOR_NAME.VERBATIM && questionType !== CHART_INDICATOR_NAME.VERBATIM_PN,
    };

    return (
        <FlexBox>
            {useChartElement().map((chart) => {
                const isDisabled = isDisabledChart[selectedQuestion?.type](chart.key, selectedQuestion?.isMultiple);

                return (
                    <CardStyled
                        key={chart.key}
                        disabled={isDisabled}
                        onClick={
                            !isDisabled
                                ? () => handleClick(chart.key, chart?.nextStep)
                                : undefined
                        }
                    >
                        {chart.image}
                        <TypographyStyledGlobal
                            margin='10px auto auto'
                            weight={fontWeight.regular}
                        >
                            {chart.title}
                        </TypographyStyledGlobal>

                    </CardStyled>
                );
            })}
            <ActionBackButtonStyled
                lighten={1}
                onClick={() => setCurrentContent((selectedQuestion.type === QUESTION_TYPE.OPEN || selectedQuestion.type === QUESTION_TYPE.NUMERIC) ? 'selectIndicatorQuestion' : 'refineIndicatorAnswer')}
                variant="text"
                disableRipple
            >
                Retour
            </ActionBackButtonStyled>
        </FlexBox>
    );
};

export default SelectIndicatorDisplayType;
