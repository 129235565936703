import CloseIcon from '@mui/icons-material/Close';
import { Modal } from '@mui/material';
import styled from 'styled-components';
import { colors } from '../../../theme';
import { ReactComponent as VerbatimImage } from '../../../assets/charts/global-verbatim.svg';

export const CloseIconModalStyled = styled(CloseIcon)`
    && {
        position: absolute;
        right: 16px;
        top: 16px;
        cursor: pointer;
        padding: 8px;
        transition: color 400ms ease-in-out;
        &:hover {
            color: ${colors.grey100};
            transition: color 400ms ease-in-out;
        }
    }
`;

export const ModalStyled = styled(Modal)`
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
`;

export const AllVerbatimCardHeader = styled.div`
    && {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin: 15px 0px 8px 0px;
    }
`;

export const VerbatimImageStyled = styled(VerbatimImage)`
    && {
        height: auto;
        width: 93px;
        padding-right:20px;
    }
`;
