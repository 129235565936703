import React, { useState, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TypographyStyledGlobal, fontWeight } from '../../../theme';
import AllVerbatimModal from '../../Modals/DashboardModal/allVerbatimModal';
import { ClauseFilterLabelProps } from '../dashboardInterfaces';
import { CardStyled, QuoteVerbatimStyled, TextVerbatimStyled, VerbatimContainerStyled, AllVerbatimContainerStyled } from '../styled';
import { ReactComponent as VerbatimImage } from '../../../assets/charts/global-verbatim.svg';
import { ReactComponent as VerbatimQuote } from '../../../assets/verbatim/quote.svg';

type GlobalVerbatimIndicatorProps = {
    question: any,
    verbatims: any,
    selectedClauseFilterLabel?: Array<ClauseFilterLabelProps>
}

const GlobalVerbatimIndicator: FC<GlobalVerbatimIndicatorProps> = ({ question, verbatims, selectedClauseFilterLabel }) => {
    const [openModal, setOpenModal] = useState(false);
    const displayedVerbatims = verbatims ? verbatims.datas.slice(0, 5) : [];
    const { t } = useTranslation('dashboard');

    return (
        <>
            <AllVerbatimContainerStyled>
                {displayedVerbatims.map((verbatim: any) => (
                    <VerbatimContainerStyled key={verbatim.interviewId}>
                        <QuoteVerbatimStyled>
                            <VerbatimQuote />
                        </QuoteVerbatimStyled>
                        <TextVerbatimStyled>
                            {verbatim.openData}
                        </TextVerbatimStyled>
                    </VerbatimContainerStyled>

                ))}
            </AllVerbatimContainerStyled>
            { displayedVerbatims.length > 0 && (
                <CardStyled margin='0px 0px 10px auto' onClick={() => setOpenModal(true)}>
                    <VerbatimImage />
                    <TypographyStyledGlobal
                        margin='0px'
                        weight={fontWeight.regular}
                    >
                        {t('addIndicatorModal.addGlobalVerbatimIndicator.displayAllVerbatimTitle')}
                    </TypographyStyledGlobal>
                </CardStyled>
            )}
            <AllVerbatimModal openModal={openModal} questionLabel={question.label} selectedClauseFilterLabel={selectedClauseFilterLabel} setOpenModal={setOpenModal} verbatims={verbatims} />
        </>
    );
};

export default GlobalVerbatimIndicator;
